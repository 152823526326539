import { useTranslations } from '@/i18n/provider';

type SignInBoxProps = {
  loading?: boolean;
  onSubmit?: () => void;
};

export function SignInBox({ loading, onSubmit }: SignInBoxProps) {
  return (
    <div className="max-sm:px-5 p-10 border-[1px] border-medium-grey max-w-[480px] max-sm:w-full max-sm:mx-4">
      {loading ? <SignInBoxLoading /> : <SignInForm onSubmit={onSubmit} />}
    </div>
  );
}

function SignInForm({ onSubmit }: Pick<SignInBoxProps, 'onSubmit'>) {
  const labels = useTranslations().loginPage;

  return (
    <form aria-label="signin">
      <h1 className="text-[18px] mb-2 uppercase border-b-[2px] border-light-grey pb-2 font-medium">
        {labels.login_popup_header}
      </h1>
      <p className="mb-5 text-sm">{labels.login_popup_message}</p>
      <button
        // todo: create universal button component with onClick (not a link)
        className="p-4 bg-primary hover:bg-primary-800 active:bg-primary-700 text-white w-full uppercase"
        onClick={() => onSubmit?.()}
      >
        {labels.login_button}
      </button>
    </form>
  );
}

function SignInBoxLoading() {
  const labels = useTranslations().loginPage;

  return (
    <h1 className="text-[18px] mb-2 uppercase border-b-[2px] border-light-grey pb-2 font-medium">
      {labels.login_progress}
    </h1>
  );
}
